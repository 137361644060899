import React, { useEffect, useState } from 'react'
import "./dashboard.css"
import icon1 from '../../components/assets/images/support (6).png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSmile,
    faTachometerAlt,
    faStore,
    faChartPie,
    faCommentDots,
    faUsers,
    faCog,
    faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    faBars,
    faSearch,
    faBell,
    faChevronRight,
    faCloudDownloadAlt,
    faCalendarCheck,
    faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

export default function Dashboard() {

    const [isSidebarHidden, setIsSidebarHidden] = useState(window.innerWidth < 768);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('overview');

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarHidden(window.innerWidth < 768);
            if (window.innerWidth > 576) {
                setIsSearchFormVisible(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setIsSidebarHidden((prev) => !prev);
    };

    const toggleSearchForm = () => {
        if (window.innerWidth < 576) {
            setIsSearchFormVisible((prev) => !prev);
        }
    };

    const handleDarkModeToggle = (e) => {
        setIsDarkMode(e.target.checked);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);


    return (
        <>
            <body className='dashboard-body'>
                {/* <!-- SIDEBAR --> */}
                <section id="sidebar" className={isSidebarHidden ? 'hide' : ''}>
                    <Link to="/home" className="brand">
                        <FontAwesomeIcon className="icon-side" icon={faSmile} />
                        <span className="text">PROCUREG</span>
                    </Link>
                    <ul className="side-menu top">
                        <li className={activeMenuItem === 'overview' ? 'active' : ''}>
                            <a href="#" onClick={() => setActiveMenuItem('overview')}>
                                <FontAwesomeIcon className="icon-side" icon={faTachometerAlt} />
                                <span className="text">OVERVIEW</span>
                            </a>
                        </li>
                        <li className={activeMenuItem === 'products' ? 'active' : ''}>
                            <a href="#" onClick={() => setActiveMenuItem('products')}>
                                <FontAwesomeIcon className="icon-side" icon={faStore} />
                                <span className="text">PRODUCTS</span>
                            </a>
                        </li>
                        <li className={activeMenuItem === 'orders' ? 'active' : ''}>
                            <a href="#" onClick={() => setActiveMenuItem('orders')}>
                                <FontAwesomeIcon className="icon-side" icon={faChartPie} />
                                <span className="text">ORDERS</span>
                            </a>
                        </li>
                        <li className={activeMenuItem === 'users' ? 'active' : ''}>
                            <a href="#" onClick={() => setActiveMenuItem('users')}>
                                <FontAwesomeIcon className="icon-side" icon={faCommentDots} />
                                <span className="text">USERS</span>
                            </a>
                        </li>
                        <li className={activeMenuItem === 'reviews' ? 'active' : ''}>
                            <a href="#" onClick={() => setActiveMenuItem('reviews')}>
                                <FontAwesomeIcon className="icon-side" icon={faUsers} />
                                <span className="text">REVIEWS</span>
                            </a>
                        </li>
                    </ul>
                </section>
                {/* <!-- SIDEBAR --> */}

                {/* <!-- CONTENT --> */}
                <section id="content">
                    {/* <!-- NAVBAR --> */}
                    <nav>
                        <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={toggleSidebar} />
                        <a href="#" className="nav-link">Welcome back, Admin
                            <p>
                                Here are today’s stats from your online store
                            </p>
                        </a>
                        <form action="#">
                            <div className="form-input">
                                <input type="search" placeholder="Search..." />
                                <button type="submit" className="search-btn">
                                    <FontAwesomeIcon className='icon-side' icon={faSearch} />
                                </button>
                            </div>
                        </form>
                        <input type="checkbox" id="switch-mode" hidden />


                        <label htmlFor="switch-mode" className="switch-mode"></label>
                        <a href="#" className="notification">
                            <FontAwesomeIcon className='icon-side' icon={faBell} />
                            <span className="num">8</span>
                        </a>
                        <a href="#" className="profile">
                            <img src={icon1} alt="Profile" />
                        </a>
                    </nav>
                    {/* <!-- NAVBAR --> */}

                    {/* <!-- MAIN --> */}
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Dashboard</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon className='icon-side' icon={faChevronRight} />
                                    </li>
                                    <li>
                                        <a className="active" href="#">Home</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#" className="btn-download">
                                <FontAwesomeIcon className='icon-side' icon={faCloudDownloadAlt} />
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <ul className="box-info">
                            <li>
                                <FontAwesomeIcon className='icon-side' icon={faCalendarCheck} />
                                <span className="text">
                                    <h3>1020</h3>
                                    <p>New Order</p>
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon className='icon-side' icon={faUsers} />
                                <span className="text">
                                    <h3>2834</h3>
                                    <p>Visitors</p>
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon className='icon-side' icon={faDollarSign} />
                                <span className="text">
                                    <h3>$2543</h3>
                                    <p>Total Sales</p>
                                </span>
                            </li>
                        </ul>
                    </main>
                    {/* <!-- MAIN --> */}
                </section>
                {/* <!-- CONTENT --> */}
            </body>
        </>
    )
}


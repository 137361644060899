import React, { useState } from "react";
import './prodist.css'
import prod1 from './prod-1.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCheck, faCircle, faCircleInfo, faPencil, faShop } from "@fortawesome/free-solid-svg-icons";
import ReactStars from 'react-stars'
import { ratingClasses } from "@mui/material";
import subprod1 from '../assets/images/product-1.jpeg'
import subprod2 from "../assets/images/product-2.jpeg"
import subprod3 from "../assets/images/product-3.jpeg"
import partner1 from "../assets/images/partners-1.jpeg"
import partner2 from "../assets/images/partners-2.jpeg"
import FlashDeals from "../flashDeals/FlashDeals";
import Header from "../../common/header/Header";


export default function Prodpage() {

    const [activeImage, setActiveImage] = useState(require('../assets/images/product-1.jpeg'));
    const [navOpen, setNavOpen] = useState(false);

    const productImages = [
        require('../assets/images/product-1.jpeg'),
        require("../assets/images/product-2.jpeg"),
        require("../assets/images/product-3.jpeg"),
    ];

    const changeImage = (src) => {
        setActiveImage(src);
    };

    return (
        <>
         <Header/>

            <section className="Product-page-ist">

                <div className="second-nav">
                    <nav
                        style={{
                            "--bs-breadcrumb-divider":
                                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E\")",
                        }}
                        aria-label="breadcrumb"
                    >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Products
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Power Tools
                            </li>
                        </ol>
                    </nav>
                </div>

                <div className="product d-flex">
                    <div className="column-xs-12 col-lg-4">
                        <div className="product-gallery">
                            <div className="product-image">
                                <img className="active" src={activeImage} alt="Product" />
                            </div>
                            <ul className="image-list">
                                {productImages.map((src, index) => (
                                    <li key={index} className="image-item">
                                        <img src={src} alt={`Thumbnail ${index + 1}`} onClick={() => changeImage(src)} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="column-xs-12 col-lg-5 description">
                        <h1>IP Camera</h1>
                        <h2>1080p HD TVI AHD CVI CVBS CCTV Camera Wide Angle Security Camera Outdoor 180 Degree to Offer High Image Quality with 30pcs IR LEDs Long Distance</h2>
                        <div className="description">
                            <p><span style={{ color: "skyblue", fontSize: "22px" }}>96%</span> <span style={{ color: "grey", fontStyle: "italic" }}>of respondents would recommend this to a friend</span></p> <div>
                                <p style={{ fontWeight: "500", borderBottom: "2px solid #2f4d2a", width: "23%", paddingBottom: "7px" }} className="pt-3">Features & Benefits :</p>

                                <ul style={{
                                    color: "#333333",
                                    listStyleType: "none",
                                }}>
                                    <li style={{
                                        margin: "0 0 10px",
                                        position: "relative",
                                        paddingLeft: "25px"
                                    }}>
                                        <span style={{
                                            position: "absolute",
                                            left: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#2f4d2a",
                                            display: "inline-block"
                                        }}></span>
                                        Groundbreaking 1600mg Liposomal Vitamin C Complex
                                    </li>
                                    <li style={{
                                        margin: "0 0 10px",
                                        position: "relative",
                                        paddingLeft: "25px"
                                    }}>
                                        <span style={{
                                            position: "absolute",
                                            left: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#8c8c8c",
                                            display: "inline-block"
                                        }}></span>
                                        Easy to swallow and miraculous absorption
                                    </li>
                                    <li style={{
                                        margin: "0 0 10px",
                                        position: "relative",
                                        paddingLeft: "25px"
                                    }}>
                                        <span style={{
                                            position: "absolute",
                                            left: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#8c8c8c",
                                            display: "inline-block"
                                        }}></span>
                                        Satisfaction assured with a risk-free purchase
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                STYLE :
                                                2MP Analog 180 Degree
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className="d-flex">
                                                    <div className="">
                                                        <img src={subprod1} className="c" style={{ height: "10vh", width: "100%" }} />
                                                    </div>
                                                    <div className="">
                                                        <img src={subprod2} className="c" style={{ height: "10vh", width: "100%" }} />
                                                    </div>
                                                    <div className="">
                                                        <img src={subprod3} className="c" style={{ height: "10vh", width: "100%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        {/* <button className="add-to-cart">Add To Cart</button> */}
                    </div>
                    <div className="column-xs-12 col-lg-3" style={{ boxShadow: "10px 10px 40px #eeeeee", top: "20px", height: "fit-content", padding: "2rem", borderRadius: "10px" }}>
                        <div className="">
                            <p style={{ fontWeight: "bold" }}>Item #: 89871746</p>
                        </div>

                        <div className="">
                            <span style={{ fontSize: "27px", fontWeight: "bold", color: "#2f4d2a" }}>INR 4817
                            </span>
                        </div>

                        <div className="">
                            <div className="d-flex ">
                                <ReactStars count={5} onChange={ratingClasses} size={30} className="mt-2" />
                                <p style={{ paddingTop: "20px", color: "#2f4d2a" }}> &nbsp;&nbsp;&nbsp;5 rating&nbsp;&nbsp;&nbsp;</p>
                            </div>
                        </div>

                        <div className="pt-2 pb-3">
                            <div>
                                <span style={{ fontWeight: "500", color: "#2f4d2a" }}>Stock :</span>
                                <span style={{ color: "#2f4d2a" }}> 99 &nbsp; </span><br />
                                <button className="btn btn-outline-dark  m-2">-</button>
                                <button className="btn btn-outline-dark  m-2">1</button>
                                <button className="btn btn-outline-dark  m-2">+</button>
                            </div>
                        </div>

                        <div className="pt-2 pb-2">
                            <button className="btn btn-secondary text-white c2">ADD TO CART</button>
                        </div>

                        <div className="pt-2 pb-2">
                            <button className="btn btn-dark text-white c2">BUY NOW</button>
                        </div>

                        <div className="pt-2 ">
                            <p style={{ fontStyle: "italic", fontSize: "15px", color: "#2f4d2a", fontWeight: "bold" }}>Fastest cross-border delivery</p>
                        </div>

                        <div className="pb-2">
                            <span style={{ fontSize: "14px", color: "#2f4d2a", fontWeight: "bold" }}>Our Top Logistics Partners</span>
                            <div className="mt-2">
                                <img src={partner1} className="img-fluid" />
                                <img src={partner2} className="img-fluid ms-2" />
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section className="container">
                <h3 style={{ borderBottom: "3px solid #2f4d2a", width: "222px", paddingBottom: "10px", marginBottom: "30px" }}>Product Details</h3>
                <div class="d-flex gap-3">
                    <div class="col-sm-6 mb-3 mb-sm-0">
                        <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                            <div class="card-body">
                                <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Specifications</h5>
                                <p class="card-text mt-3">Brand name : Boltz Corporation</p>
                                <p class="card-text">Efficiency (%) : 0.75</p>
                                <p class="card-text">Country of Origin : India</p>
                                <p class="card-text">Lifting Weight  :Single Hook-750Kg, Double Hook-1500Kg</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                            <div class="card-body">
                                <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Description</h5>
                                <p class="card-text mt-3">    At vivamus platea nibh in vitae risus. Dui eget ultricies tristique viverra cras euismod enim placerat. Consequat faucibus at sit morbi molestie pulvinar adipiscing blandit morbi. Non justo sed enim ac. Elit morbi enim duis eget. Ac in feugiat massa felis nisl tempor ac. Mauris lacus sapien ut amet facilisi. Dictumst pellentesque feugiat at volutpat nisl. Interdum ut in vivamus eget tincidunt mauris. Erat a phasellus tempor pellentesque. Euismod et augue vivamus lacus nibh in rhoncus.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mt-3 mb-5">
                <div class="d-flex gap-3">
                    <div class="col-sm-6 mb-3 mb-sm-0">
                        <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                            <div class="card-body">
                                <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Specifications</h5>
                                <p class="card-text mt-3">With supporting text below as a natural lead-in to additional content.</p>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card" style={{ height: "250px", border: "2px solid #2f4d2a" }}>
                            <div class="card-body">
                                <h5 class="card-title" style={{ borderLeft: "6px solid #222" }}>&nbsp; Reviews</h5>
                                <p class="card-text mt-3">NO REVIEWS FOR THIS PRODUCT</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}



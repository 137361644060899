import React, { useEffect, useState } from "react"
import "./style.css"
import { Link } from "react-router-dom";
import Header from "../header/Header";

const NewArrivals = () => {

  const [isActive, setIsActive] = useState(false);

  const handleRegisterClick = () => {
    setIsActive(true);
  };

  const handleLoginClick = () => {
    setIsActive(false);
  };

  return (
    <>
      <Header />
      <section className="body-pro-form">
        <div className={`container-fluid ${isActive ? 'active' : ''}`}>
          <div className="form-box login">
            <form action="#">
              <h1>I’m a seller</h1>

              <div className="input-box d-flex">
                <div className="input-box me-2">
                  <input type="email" placeholder="First name" required />
                </div>
                <div className="input-box ">
                  <input type="email" placeholder="Last name" required />
                </div>
              </div>

              <div className="input-box">
                <input type="email" placeholder="Email ID" required />
                <i className="fas fa-envelope"></i>
              </div>

              <div className="d-flex">
                <div className="input-box me-2">
                  <input type="email" placeholder="Business name" required />
                </div>
                <div className="input-box ">
                  <input type="email" placeholder="Business email ID" required />
                </div>
              </div>

              <div className="d-flex">
                <div className="input-box me-2">
                  <input type="email" placeholder=" contact number" required />
                </div>
                <div className="input-box ">
                  <input type="email" placeholder=" GST number" required />
                </div>
              </div>

              <div className="input-box">
                <input type="password" placeholder="Business address" required />
              </div>

              <div className="input-box">
                <input type="password" placeholder="Password" required />
                <i className="fas fa-lock"></i>
              </div>

              <div className="input-box">
                <input type="password" placeholder="Confrim password" required />
                <i className="fas fa-lock"></i>
              </div>
              <div className=" d-flex  mb-3">
                <input className="me-2" type="checkbox" />
                <label for="password" class="chekbox-label" style={{ fontSize: "12px" }}>By clicking Sign up, you agree to Procureg's
                  <span style={{ textDecoration: "underline", color: "#2f4d2a", fontWeight: "bold" }}>Terms of conditions</span> and <span>Privacy policy
                  </span></label>
              </div>

              <button type="submit" className="btn">Login</button>

              <div className="forgot-link">
                <a href="#" >
                  <Link to="/LOGIN" >
                    Already have an account? <span style={{ textDecoration: "underline" }}>login</span>
                  </Link></a>

              </div>

            </form>

          </div>

          <div className="form-box register">
            <form action="#">
              <h1>I’m a buyer</h1>


              <div className="input-box d-flex">
                <div className="input-box m-2">
                  <input type="email" placeholder="First name" required />
                </div>
                <div className="input-box m-2">
                  <input type="email" placeholder="Last name" required />
                </div>
              </div>


              <div className="input-box">
                <input type="text" placeholder="Email ID" required />
                <i className="fas fa-user"></i>
              </div>
              <div className="input-box">
                <input type="password" placeholder="Password" required />
                <i className="fas fa-lock"></i>
              </div>
              <div className="input-box">
                <input type="password" placeholder="Confrim password" required />
                <i className="fas fa-lock"></i>
              </div>
              <div className=" d-flex  mb-3">
                <input className="me-2" type="checkbox" />
                <label for="password" class="chekbox-label" style={{ fontSize: "12px" }}>By clicking Sign up, you agree to Procureg's
                  <span style={{ textDecoration: "underline", color: "#2f4d2a", fontWeight: "bold" }}>Terms of conditions</span> and <span>Privacy policy
                  </span></label>
              </div>
              <button type="submit" className="btn">Register</button>

              <div className="forgot-link">
                <a href="#" >
                  <Link to="/LOGIN" >
                    Already have an account? <span style={{ textDecoration: "underline" }}>login</span>
                  </Link></a>

              </div>

            </form>
          </div>

          <div className="toggle-box">
            <div className="toggle-panel toggle-left">
              <h1>I’m a buyer</h1>
              <p>GET STARTED WITH YOUR PROCUREMENT GLOCALLY?</p>
              <button className="btn register-btn" onClick={handleRegisterClick}>Click Here</button>
            </div>

            <div className="toggle-panel toggle-right">
              <h1>I’m a seller!</h1>
              <p>GET STARTED WITH YOUR PROCUREMENT GLOCALLY?</p>
              <button className="btn login-btn" onClick={handleLoginClick}>Click Here</button>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default NewArrivals

import React from "react"
import "./Header.css"
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import supportbanner from '../../components/assets/images/supportbanner.png'
import icon1 from '../../components/assets/images/support (1).png'
import icon2 from '../../components/assets/images/support (2).png'
import icon3 from '../../components/assets/images/support (3).png'
import icon4 from '../../components/assets/images/support (4).png'
import icon5 from '../../components/assets/images/support (5).png'
import icon6 from '../../components/assets/images/support (6).png'
import Header from "./Header";


export default function Support() {
    return (
        <>
           <Header/>
            <section >
                <img src={supportbanner} alt="banner" className="col-12" />
            </section>

            <section className="container support-pro-sec">
                <CardGroup className="gap-4 CardGroup ">
                    <Card className="card text-center p-3 ">
                        <div className="icon-bg">
                            <FontAwesomeIcon
                                icon={faPhone}
                                size="2x"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title>Phone</Card.Title>
                            <Card.Text>
                                +91 99438 94429
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="text-center p-3">
                        <div className="icon-bg">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                size="2x"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title>E-mail</Card.Title>
                            <Card.Text>
                                contact@istretail.com
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="text-center p-3">
                        <div className="icon-bg">
                            <FontAwesomeIcon
                                icon={faLocation}
                                size="2x"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title>Location</Card.Title>
                            <Card.Text>
                                View on google maps
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </section>

            <section className="container support-pro-sec2">
                <CardGroup className="gap-4 CardGroup ">
                    <Card className="card  p-3 ">
                        <div className="icon-bg">
                            <FontAwesomeIcon
                                icon={faPhone}
                                size="1x"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title>Need any assistance</Card.Title>
                            <Card.Text>
                                ProcureG is here to serve you
                            </Card.Text>
                            <button type="button" class="btn btn-secondary">Chat now</button>
                        </Card.Body>
                    </Card>

                    <Card className="card p-3">
                        <div className="icon-bg">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                size="1x"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title>FAQ’s</Card.Title>
                            <Card.Text>
                                Manage your orders easily
                            </Card.Text>
                            <button type="button" class="btn btn-secondary">View all</button>
                        </Card.Body>
                    </Card>

                </CardGroup>
            </section>

            <section className="support-pro-sec3 mt-5">

                <CardGroup className="container gap-4 CardGroup ">

                    <Card className="card  p-3 ">
                        <div className="icon-bg">
                            <img src={icon6} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>Helpline Number</Card.Title>
                            <Card.Text className="desc">
                                guarantee for all products
                            </Card.Text>

                        </Card.Body>
                    </Card>

                    <Card className="card p-3">
                        <div className="icon-bg">
                            <img src={icon5} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>Return within 7 days</Card.Title>
                            <Card.Text className="desc">
                                of receiving your order
                            </Card.Text>

                        </Card.Body>
                    </Card>

                    <Card className="card p-3">
                        <div className="icon-bg">
                            <img src={icon4} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>100% Safe & Secure Payments</Card.Title>
                            <Card.Text className="desc">
                                Pay using secure payment methods
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </CardGroup>

                <CardGroup className="container gap-4 CardGroup ">

                    <Card className="card  p-3 ">
                        <div className="icon-bg">
                            <img src={icon1} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>100% ORIGINAL</Card.Title>
                            <Card.Text className="desc">
                                guarantee for all products
                            </Card.Text>

                        </Card.Body>
                    </Card>

                    <Card className="card p-3">
                        <div className="icon-bg">
                            <img src={icon3} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>Complete products</Card.Title>
                            <Card.Text className="desc">
                                guarantee for all products
                            </Card.Text>

                        </Card.Body>
                    </Card>

                    <Card className="card p-3">
                        <div className="icon-bg">
                            <img src={icon2} alt="icon" className="img-fluid" />
                        </div>
                        <Card.Body>
                            <Card.Title>Buyer Protection</Card.Title>
                            <Card.Text className="desc">
                                Committed to smooth shopping.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </CardGroup>

            </section>

        </>
    )
}



const Tdata = [
  {
    cover: "./images/top/category-1.png",
    para: "headphone",
    desc: "3k orders this week",
  },
  {
    cover: "./images/top/category-2.png",
    para: "watch",
    desc: "4k orders this week",
  },
  {
    cover: "./images/top/category-3.png",
    para: "sunglass",
    desc: "6k orders this week",
  },
  {
    cover: "./images/top/category-2.png",
    para: "watch",
    desc: "4k orders this week",
  },
  {
    cover: "./images/top/category-3.png",
    para: "sunglass",
    desc: "6k orders this week",
  },
]

export default Tdata

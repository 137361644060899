import React from "react"
import "./Home.css"
import Carousel from 'react-bootstrap/Carousel';
import BannerImage1  from '../assets/images/Banner-1.png';
import BannerImage2  from '../assets/images/Banner-2.png';
import BannerImage3  from '../assets/images/Banner-3.png';
import BannerImage4  from '../assets/images/Banner-4.png';

const Home = () => {
  return (
    <>                                
        <div>
          <IndividualIntervalsExample />
        </div>
    </>
  )
}

export default Home

function IndividualIntervalsExample() {
  return (
    <Carousel>
      <Carousel.Item interval={1000}>
       <img src={BannerImage1} alt="Banner" text="First slide" />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item interval={500}>
       <img src={BannerImage2} alt="Banner" text="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={BannerImage3} alt="Banner" 
        text="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={BannerImage4} alt="Banner" 
        text="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
}

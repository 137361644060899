import React from "react"
import procureg from '../../components/assets/images/procure-g-logo.png'
import Header from "../header/Header"

const Login = () => {
  return (
    <>

      <Header />
      <div className="container" id="container-login-pro">

        <div id="left">
          <h1 id="welcome">Welcome</h1>
          <div style={{ margin: "20px" }}>
            <img src={procureg} alt="logo" className="img-fluid" style={{ height: "60px", width: "fit-content" }} />
          </div>
        </div>

        <div id="right">
          <h1 id="login">LogIn</h1><br />
          <input type="email" id="email" class="client-info" />
          <label for="email">Email</label>
          <input type="password" id="password" class="client-info" />
          <label for="password">Password</label>
          <input type="submit" id="submit" class="client-info" value="Submit" />
          <div className="text-center">
            <a href="#">New user at ProcureG?   <span style={{ textDecoration: "underline", color: "#2f4d2a", fontWeight: "bold" }}> sign up</span></a>
          </div>
          <button class="social" id="facebook">connect with facebook</button>
          <button class="social" id="google">connect with google</button>
        </div>
      </div>

    </>
  )
}

export default Login

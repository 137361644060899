import React, { useState } from "react"
import "./App.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Header from "./common/header/Header"
import Pages from "./pages/Pages"
import Data from "./components/Data"
import Cart from "./common/Cart/Cart"
import Footer from "./common/footer/Footer"
import Sdata from "./components/shops/Sdata"
import NewArrivals from "./common/LOGIN-SIGN-USER/SIGNIN"
import Login from "./common/LOGIN-SIGN-USER/LOGIN"
import Prodpage from "./components/Productpage/Productist"
import Dashboard from "./common/Dashboard/dashboard"
import Support from "./common/header/support"
import Ourbusiness from "./common/OURBUSINESS/ourbusiness"
import ProcuregSeller from "./common/SELLERPAGE/sellerpage"
import Trackorderpage from "./common/TRACKORDER/Trackorder"

function App() {
  // Step 1 :
  const { productItems } = Data
  const { shopItems } = Sdata
  // Step 2 :
  const [CartItem, setCartItem] = useState([])
  // Step 4 :
  const addToCart = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id)
    if (productExit) {
      setCartItem(CartItem.map((item) => (item.id === product.id ? { ...productExit, qty: productExit.qty + 1 } : item)))
    } else {
      setCartItem([...CartItem, { ...product, qty: 1 }])
    }
  }

  // Step 6 :
  const decreaseQty = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id)
    if (productExit.qty === 1) {
      setCartItem(CartItem.filter((item) => item.id !== product.id))
    } else {
      setCartItem(CartItem.map((item) => (item.id === product.id ? { ...productExit, qty: productExit.qty - 1 } : item)))
    }
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Pages productItems={productItems} addToCart={addToCart} shopItems={shopItems} />} />
          <Route path='/cart' element={<Cart CartItem={CartItem} addToCart={addToCart} decreaseQty={decreaseQty} />} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="/loginsign" element={<NewArrivals />} />
          <Route path="/LOGIN" element={<Login />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/productdesc" element={<Prodpage />} />
          <Route path="/Ourbusiness" element={<Ourbusiness />} />
          <Route path="/sell" element={<ProcuregSeller />} />
          <Route path="/trackorder" element={<Trackorderpage />} />
          <Route path="/dash" element={<Dashboard />} />
          <Route path="home" element={<Pages productItems={productItems} addToCart={addToCart} shopItems={shopItems} />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App

